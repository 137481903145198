import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "taltech-testlab",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#taltech-testlab",
        "aria-label": "taltech testlab permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TalTech Testlab`}</h1>
    <p><strong parentName="p">{` This page is intended for TalTech students who possess valid UNIID. `}<br />{` You cannot use these services without access to your UNIID account. `}</strong></p>
    <p>{`TalTech Testlab offers a suite of multiple services designed to help you test your mobile applications on physical devices.`}</p>
    <p><a parentName="p" {...{
        "href": "lab.jpeg"
      }}><img parentName="a" {...{
          "src": "/lab.jpeg",
          "alt": "Alt"
        }}></img></a></p>
    <h2 {...{
      "id": "services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#services",
        "aria-label": "services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Services`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/services/android"
        }}>{`Android`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Screenshot testing`}</li>
          <li parentName="ul">{`Espresso testing`}</li>
          <li parentName="ul">{`Virtual lab`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/services/ios"
        }}>{`IOS`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Screenshot testing`}</li>
          <li parentName="ul">{`Espresso testing`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/services/flutter"
        }}>{`Flutter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/services/react-native"
        }}>{`React Native`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/services/devices"
        }}>{`Devices`}</a></li>
    </ul>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      